import React from 'react';

export default function VerticalPanel(props: any) {
  return (
    <div className="vertical-panel-content">
      <div className="vertical-panel-info">
        <div className="vertical-panel-title">{props.slogan}</div>
        {props.facebookUrl || props.linkedInUrl ? <div className="line" /> : null}
      </div>
      <ul className="social-list">
        <li>
          {props.linkedInUrl && (
            <a href={props.linkedInUrl} target="_blank" className="fa fa-linkedin" />
          )}
        </li>
        <li>
          {props.facebookUrl && (
            <a href={props.facebookUrl} target="_blank" className="fa fa-facebook" />
          )}
        </li>
      </ul>
    </div>
  );
}
